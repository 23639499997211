import React from "react";
import { WhatsAppWidget } from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css"; // Make sure to import the styles

const WhatsAppButton = () => {
  return (
    <div style={buttonContainerStyle}>
      {/* WhatsApp widget wrapper with dynamic visibility */}
      <div
        style={{
          position: "fixed",
          bottom: "20px",
          left: "0", // Set to 0 to align with the left side
          zIndex: "9999",
        }}
      >
        <WhatsAppWidget
          companyName="Le Marly"
          phoneNumber="+2250707977829"
          message="How can we help you!"
        />
      </div>
    </div>
  );
};

const buttonContainerStyle = {
  position: "fixed",
  bottom: "20px",
  left: "20px", // Adjust this value to control the left offset
  zIndex: "9999",
};

export default WhatsAppButton;
