import React, { useEffect, useState } from "react";
import home1 from "../../assets/images/hero-slider-1.jpg";
import { useTranslation } from "react-i18next";
import "./contact.css";
import ContactUs from "../../components/Sections/ContactUs";
import UpcomingEvents from "../../components/Sections/UpcomingEvents/UpcomingEvents";

const Contact = () => {
  const [t, i18n] = useTranslation("global");
  const [showBackToTop, setShowBackToTop] = useState(false);

  // Add an event listener to handle scroll events
  const handleScroll = () => {
    // Show the "Back to Top" button when the user scrolls beyond a certain point
    if (window.scrollY > 100) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };

  // Add the event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Function to scroll back to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Add smooth scrolling behavior
    });
  };

  return (
    <div>
      {/* [PRELOADER] */}

      <div className="preload" data-preaload>
        <div className="circle"></div>
        <p className="text">LE MARLY</p>
      </div>

      <main>
        <article>
          {/* HERO */}

          {/* TESTIMONIALS */}

          <section
            className="section testi text-center has-bg-image"
            aria-label="testimonials"
          >
            <div className="container">
              <div className="wrapper">
                <div className="separator"></div>
                <div className="separator"></div>
                <div className="separator"></div>
              </div>
            </div>
          </section>

          {/* RESERVATION */}

          <section className="reservation">
            <ContactUs />
          </section>

          {/* EVENT */}

          <section className="section event bg-black-10" aria-label="event">
            {/*  <UpcomingEvents /> */}
          </section>
        </article>
      </main>

      {/* BACK TO TOP */}

      <button
        href=""
        onClick={scrollToTop}
        className="back-top-btn active"
        aria-label="back to top"
        data-back-top-btn
      >
        <ion-icon name="chevron-up" aria-hidden="true"></ion-icon>
      </button>
    </div>
  );
};

export default Contact;
