import React from "react";
import Shape7 from "../../../assets/images/shape-7.png";
import Shape8 from "../../../assets/images/shape-8.png";
import Features1 from "../../../assets/images/features-icon-1.png";
import Features2 from "../../../assets/images/features-icon-2.png";
import Features3 from "../../../assets/images/features-icon-3.png";
import Features4 from "../../../assets/images/features-icon-4.png";
import { useTranslation } from "react-i18next";

const WhyChooseUs = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div className="container">
      <p className="section-subtitle label-2">{t("features.ft-h")}</p>

      <h2 className="headline-1 section-title">{t("features.ft-h2")}</h2>

      <ul className="grid-list">
        <li className="feature-item">
          <div className="feature-card">
            <div className="card-icon">
              <img
                src={Features1}
                width="100"
                height="80"
                loading="lazy"
                alt="icon"
              />
            </div>

            <h3 className="title-2 card-title">{t("features.ft-h3")}</h3>

            <p className="label-1 card-text">{t("features.ft-p")}</p>
          </div>
        </li>

        <li className="feature-item">
          <div className="feature-card">
            <div className="card-icon">
              <img
                src={Features2}
                width="100"
                height="80"
                loading="lazy"
                alt="icon"
              />
            </div>

            <h3 className="title-2 card-title">{t("features.ft-h4")}</h3>

            <p className="label-1 card-text">{t("features.ft-p1")}</p>
          </div>
        </li>

        <li className="feature-item">
          <div className="feature-card">
            <div className="card-icon">
              <img
                src={Features3}
                width="100"
                height="80"
                loading="lazy"
                alt="icon"
              />
            </div>

            <h3 className="title-2 card-title">{t("features.ft-h5")}</h3>

            <p className="label-1 card-text">{t("features.ft-p2")}</p>
          </div>
        </li>

        <li className="feature-item">
          <div className="feature-card">
            <div className="card-icon">
              <img
                src={Features4}
                width="100"
                height="80"
                loading="lazy"
                alt="icon"
              />
            </div>

            <h3 className="title-2 card-title">{t("features.ft-h6")}</h3>

            <p className="label-1 card-text">{t("features.ft-p3")}</p>
          </div>
        </li>
      </ul>

      <img
        src={Shape7}
        width="208"
        height="178"
        loading="lazy"
        alt="shape"
        className="shape shape-1"
      />

      <img
        src={Shape8}
        width="120"
        height="115"
        loading="lazy"
        alt="shape"
        className="shape shape-2"
      />
    </div>
  );
};

export default WhyChooseUs;
