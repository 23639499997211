import React from "react";
import "../../App.css";

const VideoSection = () => {
  return (
    <section id="section-tv" className="d-block">
      <div className="container">
        <div className="row mb-5">
          <div className="col-12">
            <div className="section-content-container">
              <div className="row">
                <div className="col-12 col-md-10 mx-auto video-main">
                  <div id="video-container" className="mb-3">
                    <iframe
                      width="100%"
                      height="360"
                      src={`https://www.youtube.com/embed/A1BLSDDso8s`}
                    ></iframe>
                  </div>
                  <div id="video-container" className="mb-3 d-md-none">
                    {/* The d-md-none class makes this div hidden on medium and larger screens */}
                    <iframe
                      width="100%"
                      height="360"
                      src="https://www.youtube.com/embed/tMCSuiBX3cs"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VideoSection;
