import React, { useEffect, useState } from "react";
import Rooms from "../../components/Sections/Rooms/Room";
import Restaurant from "../../components/Sections/Restaurant/Restaurant";
import home1 from "../../assets/images/spagheti1.png";
import spagheti from "../../assets/images/spagheti1.png";
import { useTranslation } from "react-i18next";
import UpcomingEvents from "../../components/Sections/UpcomingEvents/UpcomingEvents";
import Slider from "../Slider/Slider";

const Index = () => {
  const [t] = useTranslation("global");

  const [showBackToTop, setShowBackToTop] = useState(false);
  const images = [
    "image1.jpg",
    "image2.jpg",
    "image3.jpg",
    // Add more image URLs as needed
  ];

  // Add an event listener to handle scroll events
  const handleScroll = () => {
    // Show the "Back to Top" button when the user scrolls beyond a certain point
    if (window.scrollY > 100) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };

  // Add the event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Function to scroll back to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Add smooth scrolling behavior
    });
  };

  return (
    <div>
      {/* [PRELOADER] */}

      <div className="preload" data-preaload>
        <div className="circle"></div>
        <p className="text">LE MARLY</p>
      </div>

      <main>
        <article>
          {/* HERO */}

          <section
            className="hero-about text-center"
            aria-label="home"
            id="home"
          >
            <ul className="hero-about-slider" data-hero-slider>
              {/* LIST ONE */}
              <li className="slider-item active" data-hero-slider-item>
                <div className="slider-bg">
                  <img
                    src={spagheti}
                    width="1880"
                    height="950"
                    alt=""
                    className="img-cover"
                  />
                </div>

                <p className="label-2 section-subtitle slider-reveal">
                  {t("rooms.our-r")}
                </p>

                <p className="body-2 hero-about-text slider-reveal">
                  {t("rooms.our-r2")}
                </p>
              </li>
              {/* LIST TWO */}
              <li className="slider-item" data-hero-slider-item>
                <div className="slider-bg">
                  <img
                    src={home1}
                    width="1880"
                    height="950"
                    alt=""
                    className="img-cover"
                  />
                </div>

                <p className="label-2 section-subtitle slider-reveal">
                  {t("rooms.our-r")}
                </p>

                <p className="body-2 hero-about-text slider-reveal">
                  {t("rooms.our-r1")}
                </p>
              </li>
              {/* LIST THREE */}

              <li className="slider-item" data-hero-slider-item>
                <div className="slider-bg">
                  <img
                    src={home1}
                    width="1880"
                    height="950"
                    alt=""
                    className="img-cover"
                  />
                </div>

                <p className="label-2 section-subtitle slider-reveal">
                  {t("rooms.our-r")}
                </p>

                <p className="body-2 hero-about-text slider-reveal">
                  {t("rooms.our-r1")}
                </p>
              </li>
            </ul>

            <button
              className="slider-btn prev"
              aria-label="slide to previous"
              data-prev-btn
            >
              <ion-icon name="chevron-back"></ion-icon>
            </button>

            <button
              className="slider-btn next"
              aria-label="slide to next"
              data-next-btn
            >
              <ion-icon name="chevron-forward"></ion-icon>
            </button>
          </section>

          {/* ROOMS */}
          <Restaurant />
          {/* ROOMS */}

          {/* EVENT */}

          <section className="section event bg-black-10" aria-label="event">
            {/*  <UpcomingEvents /> */}
          </section>
        </article>
      </main>

      {/* BACK TO TOP */}

      <button
        onClick={scrollToTop}
        href=""
        className="back-top-btn active"
        aria-label="back to top"
        data-back-top-btn
      >
        <ion-icon name="chevron-up" aria-hidden="true"></ion-icon>
      </button>
    </div>
  );
};

export default Index;
