import React from "react";
import Banner from "../../../assets/images/newHome.png";
import Badge from "../../../assets/images/badge-2.png";
import Shape3 from "../../../assets/images/shape-3.png";
import { useTranslation } from "react-i18next";
import lightSlider from "../../../assets/images/light-slider-01.jpg";

const OurStory = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div className="container">
      <div className="about-content">
        <h2 className="headline-1 section-title">{t("about.journey")}</h2>

        <p className="section-text">{t("about.abtus")}</p>

        <div className="contact-label">{t("about.call-b")}</div>

        <a
          href="tel:+2252721212151"
          className="body-1 contact-number hover-underline"
        >
          +2252721212151
        </a>

        <a href="/contact" className="btn btn-primary">
          <span className="text text-1">{t("hero.contactus")}</span>

          <span className="text text-2" aria-hidden="true">
            {t("hero.contactus")}
          </span>
        </a>
      </div>

      <figure className="about-banner">
        <img
          src={Banner}
          width="570"
          height="570"
          loading="lazy"
          alt="about banner"
          className="w-100"
          data-parallax-item
          data-parallax-speed="1"
        />

        <div
          className="abs-img abs-img-1 has-before"
          data-parallax-item
          data-parallax-speed="1.75"
        >
        </div>
      </figure>
    </div>
  );
};

export default OurStory;
