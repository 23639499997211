import React from "react";
import Service2 from "../../../assets/images/service-2.jpg";
import Shape1 from "../../../assets/images/shape-1.png";
import Shape2 from "../../../assets/images/shape-2.png";
import { useTranslation } from "react-i18next";
import restaurantlegrill from "../../../assets/images/restaurant-le-grill.webp";
import rooms01 from "../../../assets/images/room1.png";

const OfferedServices = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div className="container">
      <p className="section-subtitle label-2">{t("services.s-p-one")}</p>

      <h2 className="headline-1 section-title">{t("services.h2-one")}</h2>

      <p className="section-text">{t("services.par")}</p>

      <ul className="grid-list">
        <li>
          <div className="service-card">
            <a href="#" className="has-before hover:shine">
              <figure
                className="card-banner img-holder"
                style={{ "--width": 285, "--height": 336 }}
              >
                <img
                  src={restaurantlegrill}
                  width="285"
                  height="336"
                  loading="lazy"
                  alt="Breakfast"
                  className="img-cover"
                />
              </figure>
            </a>

            <div className="card-content">
              <h3 className="title-4 card-title">
                <a href="/services">{t("services.events")}</a>
              </h3>

              <a href="/services" className="btn-text hover-underline label-2">
                {t("services.l-more")}
              </a>
            </div>
          </div>
        </li>

        <li>
          <div className="service-card">
            <a href="#" className="has-before hover:shine">
              <figure
                className="card-banner img-holder"
                style={{ "--width": 285, "--height": 336 }}
              >
                <img
                  src={Service2}
                  width="285"
                  height="336"
                  loading="lazy"
                  alt="Appetizers"
                  className="img-cover"
                />
              </figure>
            </a>

            <div className="card-content">
              <h3 className="title-4 card-title">
                <a href="/services">{t("services.food")}</a>
              </h3>

              <a href="/services" className="btn-text hover-underline label-2">
                {t("services.l-more")}
              </a>
            </div>
          </div>
        </li>

        <li>
          <div className="service-card">
            <a href="#" className="has-before hover:shine">
              <figure
                className="card-banner img-holder"
                style={{ "--width": 285, "--height": 336 }}
              >
                <img
                  src={rooms01}
                  width="285"
                  height="336"
                  loading="lazy"
                  alt="Drinks"
                  className="img-cover"
                />
              </figure>
            </a>

            <div className="card-content">
              <h3 className="title-4 card-title">
                <a href="/services">{t("book.rooms")}</a>
              </h3>

              <a href="/rooms" className="btn-text hover-underline label-2">
                {t("services.view-r")}
              </a>
            </div>
          </div>
        </li>
      </ul>

      <img
        src={Shape1}
        width="246"
        height="412"
        loading="lazy"
        alt="shape"
        className="shape shape-1 move-anim"
      />
      <img
        src={Shape2}
        width="343"
        height="345"
        loading="lazy"
        alt="shape"
        className="shape shape-2 move-anim"
      />
    </div>
  );
};

export default OfferedServices;
