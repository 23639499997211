import React, { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import logo from "../../assets/images/nav-logo.png";
import EngFlag from "../../assets/images/America.jpg";
import FranceFlag from "../../assets/images/France.gif";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import axios from "axios";
import { toast } from "react-toastify";
import { CircularProgress, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  fontSize: "22px",
  height: "auto",
  display: "grid",
  placeItems: "center",
  zIndex: "10",
  opacity: "1",
  visibility: "hidden",
  transform: "translate(-50%, -50%)",
  border: "2px solid #000",
  boxShadow: "0 0 24px rgba(0, 0, 0, 0.3)",
  padding: "4px",
  "@media screen and (max-width: 850px)": {
    fontSize: "18px",
  },
  "@media screen and (max-width: 650px)": {
    fontSize: "16px",
  },
};
const color = "#fff";
const Navbar = () => {
  const navigate = useNavigate(
    "https://live.ipms247.com/booking/book-rooms-hotellemarly"
  );
  const [t, i18n] = useTranslation("global");
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalopen, setModalopen] = useState(false);
  const handleModal = () => {
    setModalopen(!modalopen);
  };
  const handleCloseModal = () => setModalopen(false);

  const handleChange = (e) => {
    const { value, name } = e.target;

    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      window.location.href =
        "https://live.ipms247.com/booking/book-rooms-hotellemarly";
      return;
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/reservation`,
        state
      );

      if (response?.data?.success) {
        toast.success(response?.data?.message);
        setState(null);
      }
    } catch (error) {
      toast.error(error?.message ?? "Something Went Wrong");
    }
    setLoading(false);
  };

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("preferredLanguage", lang);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Retrieve the user's preferred language from local storage or use 'fr' as the default
  const preferredLanguage = localStorage.getItem("preferredLanguage") || "fr";

  useEffect(() => {
    i18n.changeLanguage(preferredLanguage);
  }, [i18n, preferredLanguage]);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  const languageOptions = [
    { code: "fr", name: "Français", flag: FranceFlag },
    { code: "en", name: "English", flag: EngFlag },
    // Add more language options as needed
  ];

  const ImageStyle = {
    marginRight: "20px",
  };
  const [flag, setFlag] = useState(
    localStorage.getItem("preferredLanguage") === "en" ? EngFlag : FranceFlag
  );
  return (
    <div>
      <div className={`topbar`}>
        <div className="container">
          <address className="topbar-item">
            <div className="icon">
              <ion-icon name="location-outline" aria-hidden="true"></ion-icon>
            </div>
            <span className="span">{t("navbar.location")}</span>
          </address>
          <div className="separator"></div>
          <div className="topbar-item item-2">
            <div className="icon">
              <ion-icon name="time-outline" aria-hidden="true"></ion-icon>
            </div>
            <span className="span">{t("navbar.time")}</span>
          </div>
          <a href="tel:+2252721212151" className="topbar-item link">
            <span className="span">+2252721212151</span>
          </a>
          <div className="separator"></div>
          <div className="navbar-language-dropdown" style={{ color: "white" }}>
            <Button
              id="basic-menu-button"
              aria-controls={open ? "basic-language-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <img className="LanguageImage" style={ImageStyle} src={flag} />
            </Button>
            <Menu
              id="basic-language-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-menu-button",
              }}
            >
              {languageOptions.map((option) => (
                <MenuItem
                  key={option.code}
                  selected={preferredLanguage === option.code}
                  onClick={() => {
                    handleChangeLanguage(option.code);
                    handleClose();
                    setFlag(option.flag);
                  }}
                >
                  <img
                    className="LanguageImage"
                    style={ImageStyle}
                    src={option.flag}
                  />{" "}
                  {option.name}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>
      </div>

      {/* NAVBAR START */}
      <header
        className={`header ${isMenuOpen ? "nav-active" : ""}`}
        data-header
      >
        <div className="container">
          <a from="/" className="logo">
            <img src={logo} width="90" height="50" alt="Le Marly - Home" />
          </a>

          <button
            className="nav-open-btn"
            aria-label="open menu"
            onClick={toggleMenu}
          >
            <span className="line line-1"></span>
            <span className="line line-2"></span>
            <span className="line line-3"></span>
          </button>
          <div
            className={`overlay ${isMenuOpen ? "active" : ""}`}
            data-overlay
          ></div>
          <nav className={`navbar ${isMenuOpen ? "active" : ""}`} data-navbar>
            <button
              className="close-btn"
              aria-label="close menu"
              onClick={toggleMenu}
            >
              <ion-icon name="close-outline" aria-hidden="true"></ion-icon>
            </button>
            <a href="/" className="logo">
              <img src={logo} width="160" height="50" alt="Le Marly - Home" />
            </a>

            <ul className="navbar-list">
              <li className="navbar-item">
                <a
                  href="/"
                  className={`navbar-link hover-underline ${
                    window.location.pathname === "/" && "active"
                  }`}
                >
                  <div className="separator"></div>
                  <span className="span">{t("navbar.home")}</span>
                </a>
              </li>
              <li className="navbar-item">
                <a
                  href="/about"
                  className={`navbar-link hover-underline ${
                    window.location.pathname === "/about" && "active"
                  }`}
                >
                  <div className="separator"></div>
                  <span className="span">{t("navbar.aboutus")}</span>
                </a>
              </li>
              <li className="navbar-item">
                <a
                  href="/rooms"
                  className={`navbar-link hover-underline ${
                    window.location.pathname === "/rooms" && "active"
                  }`}
                >
                  <div className="separator"></div>
                  <span className="span">{t("navbar.accommodation")}</span>
                </a>
              </li>
              <li className="navbar-item">
                <a
                  href="/restaurant"
                  className={`navbar-link hover-underline ${
                    window.location.pathname === "/restaurant" && "active"
                  }`}
                >
                  <div className="separator"></div>
                  <span className="span">Restaurant</span>
                </a>
              </li>

              <li className="navbar-item">
                <a
                  href="/services"
                  className={`navbar-link hover-underline ${
                    window.location.pathname === "/services" && "active"
                  }`}
                >
                  <div className="separator"></div>
                  <span className="span">{t("navbar.services")}</span>
                </a>
              </li>

              <li className="navbar-item">
                <a
                  href="/gallery"
                  className={`navbar-link hover-underline ${
                    window.location.pathname === "/gallery" && "active"
                  }`}
                >
                  <div className="separator"></div>
                  <span className="span">{t("navbar.gallery")}</span>
                </a>
              </li>

              <li className="navbar-item">
                <a
                  href="/contact"
                  className={`navbar-link hover-underline ${
                    window.location.pathname === "/contact" && "active"
                  }`}
                >
                  <div className="separator"></div>
                  <span className="span">{t("navbar.contact")}</span>
                </a>
              </li>
            </ul>

            <div className="text-center">
              <p className="headline-1 navbar-title">{t("navbar.visitus")}</p>
              <address className="body-4">{t("navbar.location")}</address>
              <p className="body-4 navbar-text">{t("navbar.time")}</p>
              <div className="separator"></div>
              <p className="contact-label">{t("navbar.booking")}</p>
              <a
                href="tel:+2252721212151"
                className="body-1 contact-number hover-underline"
              >
                +2252721212151
              </a>
              <div className="separator"></div>
              <div
                className="navbar-language-dropdown"
                style={{ color: "white" }}
              >
                <Button
                  id="basic-menu-button"
                  aria-controls={open ? "basic-language-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <img className="LanguageImage" src={flag} />
                </Button>
                <Menu
                  id="basic-language-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-menu-button",
                  }}
                >
                  {languageOptions.map((option) => (
                    <MenuItem
                      key={option.code}
                      selected={preferredLanguage === option.code}
                      onClick={() => {
                        handleChangeLanguage(option.code);
                        handleClose();
                        setFlag(option.flag);
                      }}
                    >
                      <img
                        className="LanguageImage"
                        style={ImageStyle}
                        src={option.flag}
                      />{" "}
                      {option.name}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </div>
          </nav>

          <a
            className="btn btn-secondary"
            onClick={() => {
              window.open(
                "https://live.ipms247.com/booking/book-rooms-hotellemarly"
              );
            }}
            style={{ cursor: "pointer" }}
          >
            <span className="text text-1">{t("navbar.reservation")}</span>
            <span className="text text-2" aria-hidden="true">
              {t("navbar.reservation")}
            </span>
          </a>
        </div>
      </header>
      {/* NAVBAR END */}
      <Modal
        open={modalopen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            className={`Hero-Modal-Navbar${modalopen ? "-Active" : ""}`}
            style={{ width: "200%" }}
          >
            <form className={"form-left"} onSubmit={handleSubmit}>
              <div className="close-icon" onClick={handleModal}>
                <ion-icon name="close-outline"></ion-icon>
              </div>
              <div className="input-wrapper input-wrappers input-wrapper-navbarmodal">
                <div className="box image-boxs" style={{ width: "49%" }}>
                  <div>
                    <i class="fas fa-calendar-check"></i>
                    <label>{t("book.checkin")}</label>
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TextField
                      type="date"
                      name="checkInDate"
                      placeholder="Check-in-Date"
                      autocomplete="off"
                      className="input-field input-fields"
                      onChange={handleChange}
                      value={state?.checkInDate}
                      required
                      sx={{
                        width: "50%",
                        "& input": {
                          color: "white",
                          textTransform: "uppercase",
                          fontFamily: "DM Sans, sans-serif",
                          fontSize: "22px",
                          height: "56px",
                          padding: "0px",
                          paddingLeft: "14px",
                          paddingRight: "14px",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "#fff",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </LocalizationProvider>
                  {/* <input
                    type="date"
                    style={{ width: "50%" }}
                    name="checkInDate"
                    placeholder="Check-in-Date"
                    autocomplete="off"
                    className="input-field input-fields"
                    onChange={handleChange}
                    value={state?.checkInDate}
                    required
                  /> */}
                </div>

                <div className="box image-boxs" style={{ width: "49%" }}>
                  <div>
                    <i class="fas fa-calendar-times"></i>
                    <label>{t("book.checkout")}</label>
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TextField
                      className="input-field input-fields"
                      type="date"
                      style={{ width: "50%" }}
                      placeholder="Check-out-Date"
                      name="checkOutDate"
                      onChange={handleChange}
                      value={state?.checkOutDate}
                      required
                      sx={{
                        width: "50%",
                        "& input": {
                          color: "white",
                          textTransform: "uppercase",
                          fontFamily: "DM Sans, sans-serif",
                          fontSize: "22px",
                          height: "56px",
                          padding: "0px",
                          paddingLeft: "14px",
                          paddingRight: "14px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </LocalizationProvider>
                </div>

                <div className="box image-boxs" style={{ width: "49%" }}>
                  <div className="flexTitle">
                    <div>
                      <i class="fas fa-user"></i>
                      <label>{t("book.adults")}</label>
                    </div>
                  </div>
                  <input
                    className="input-field input-fields"
                    style={{ width: "50%" }}
                    type="number"
                    placeholder="0"
                    name="adults"
                    onChange={handleChange}
                    value={state?.adults}
                    required
                  />
                </div>
                <div className="box image-boxs" style={{ width: "49%" }}>
                  <div className="flexTitle">
                    <div>
                      <i class="fas fa-child"></i>
                      <label>{t("book.child")}</label>
                    </div>
                  </div>
                  <input
                    className="input-field input-fields"
                    type="number"
                    style={{ width: "50%" }}
                    placeholder="0"
                    name="children"
                    onChange={handleChange}
                    value={state?.children}
                  />
                </div>
                <div className="box image-boxs" style={{ width: "49%" }}>
                  <div className="flexTitle">
                    <div>
                      <i class="fas fa-house"></i>
                      <label>{t("book.rooms")}</label>
                    </div>
                  </div>
                  <input
                    className="input-field input-fields"
                    type="number"
                    placeholder="0"
                    style={{ width: "50%" }}
                    name="numberOfRooms"
                    onChange={handleChange}
                    value={state?.numberOfRooms}
                    required
                  />
                </div>
                <div className="box image-boxs" style={{ width: "49%" }}>
                  <div className="flexTitle">
                    <div>
                      <i class="fa-solid fa-envelope"></i>
                      <label>E-mail:</label>
                    </div>
                  </div>
                  <input
                    className="input-field input-fields"
                    type="email"
                    style={{ width: "50%" }}
                    placeholder="Email"
                    name="email"
                    onChange={handleChange}
                    value={state?.email}
                    required
                  />
                </div>
              </div>

              <button type="submit" className="btn btn-secondary">
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                    }}
                  >
                    <CircularProgress
                      style={{ height: "20px", width: "20px" }}
                      color="inherit"
                    />
                    Loading...
                  </div>
                ) : (
                  <>
                    <span className="text text-1">{t("hero.booknow")}</span>
                    <span className="text text-2" style={{ color: "white" }}>
                      {t("hero.booknow")}
                    </span>
                  </>
                )}
              </button>
            </form>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Navbar;
